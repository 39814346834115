import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { change, getFormValues, reduxForm } from "redux-form";
import {
  TextInputField,
  SelectInputField,
} from "../../../../_shared/components/ReduxFormFields";
import { countries } from "../../../../_shared/defs";
import CustomButton from "../../../../_shared/components/Button";
import CompanyField from "../_shared/forms/companyFields";
import {
  uploadMedia,
  uploadCompanyLogo,
  navigateTo,
  uiToggleModal,
} from "../../../../redux/actions";

import { formatMessagesFromError } from "../../../../_shared/functions/utils";
import Toaster from "../../../../_shared/components/Toaster";
import BusinessVerificationModal from "../_shared/Components/BusinessVerificationModal";

const Company = ({
  onSave,
  handleSubmit,
  logo = "",
  uploadMedia,
  uploadCompanyLogo,
  navigateTo,
  info,
  uiToggleModal,
}) => {
  const countryDropdown = () => {
    return countries.map(({ name, code }) => ({ label: name, value: code }));
  };

  const { tariff, profile, verified } = info || {};
  const { name } = profile || {};

  const renderFields = (
    name,
    label,
    placeholder,
    type = "text",
    component = TextInputField
  ) => (
    <CompanyField
      name={name}
      label={label}
      placeholder={placeholder}
      type={type}
      component={component}
      onclick={handleSubmit(onSave)}
      formType="companyForm"
    />
  );

  const handleUpload = (event, mediaType) => {
    const file = event.target.files;
    if (!file.length) return;

    if (mediaType === "img" && file[0].type.split("/")[0] !== "image") {
      Toaster.dismiss();
      Toaster.error(
        formatMessagesFromError("Invalid file type, check and try again.")
      );

      return;
    }

    const payload = {
      files: file,
      formAction: change,
      mediaType,
    };

    uploadMedia(payload, uploadCompanyLogo);
  };

  

  return (
    <div>
      <div className="company-field">
        <div className="company-field__label">Current plan</div>
        <div className="company-field__plan">
          <p>{tariff && tariff}</p>
          <a
            href="https://pricing.opnplatform.com/basic"
            title="Upgrade now - Pricing"
          >
              <strong>Upgrade now</strong>
            </a>
        </div>
      </div>
      <div className="company-field">
        <div className="company-field__label">Company Logo</div>
        <div className="company-field__logo">
          <label htmlFor="logo">
            <div className="overlay">
              <i className="fas fa-camera" />
            </div>
            {logo && <img src={logo} />}
            <input
              type="file"
              id="logo"
              onChange={event => handleUpload(event, "img")}
            />
          </label>
          <i className="fas fa-trash" />
        </div>
      </div>
      {renderFields(
        "company_name",
        "COMPANY NAME",
        "Company name not specified"
      )}
      {renderFields(
        "company_registration_number",
        "COMPANY REGISTRATION NUMBER",
        "Company registration number not specified"
      )}
      {renderFields(
        "description",
        "Description",
        "Description not added",
        "textarea"
      )}
      <CompanyField
        name="country"
        label="Country"
        placeholder="Country not specified"
        options={countryDropdown()}
        isSearchable
        component={SelectInputField}
        onclick={handleSubmit(onSave)}
        formType="companyForm"
      />
      {renderFields("city", "City", "City not specified")}
      {renderFields("address", "Address", "Address not specified")}
      {renderFields(
        "email",
        "Company Email",
        "Company Email not specified",
        "email"
      )}
      {renderFields("phone", "Phone", "Phone not specified", "tel")}
      {renderFields("website", "Website", "Website not specified", "url")}
      <CompanyField
        name="credit_limit"
        label="Credit limit"
        id="credit_limit"
        type="text"
       
        className="amount"
        component={TextInputField}
        placeholder="Credit limit not specified"
        onclick={handleSubmit(onSave)}
        
      />
      <div className="company-field">
        <div className="company-field__label">Verification</div>

        <div className="company-field__plan">
          <div className=" verify">
            <img
              className={verified ? "" : "verifed-img"}
              src="/images/svg/icons/verify.svg"
              alt="chat icon"
            />
            {verified ? (
              <p className="verifed">{name && name}</p>
            ) : (
              <p className="unverifed">Unverified</p>
            )}
          </div>
          {verified ? (
            <p className="verifed">verified</p>
          ) : (
            <CustomButton
              name="Verify now"
              btnType="no-border"
              disabled={tariff && tariff === "BASIC"}
              action={() => uiToggleModal()}
            />
          )}
        </div>
      </div>
      {tariff && tariff === "BASIC" ? (
        <p className="text-center">
          With a <Link to="/pricing">Business</Link> account, you verify your
          business
        </p>
      ) : null}
      <BusinessVerificationModal />
    </div>
  );
};

// Company.propTypes = propTypes;
// Company.defaultProps = defaultProps;

const formName = "companyForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  //   validate: PreOrderValidationFunc,
})(Company);

// export default connectedForm;
export default connect(
  state => ({
    formData: getFormValues(formName)(state),
    info: state.settings.companyInfo,
  }),
  {
    change,
    uploadMedia,
    uploadCompanyLogo,
    navigateTo,
    uiToggleModal,
  }
)(connectedForm);

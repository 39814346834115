export default {
  GOOGLE_MAPS_KEY: "AIzaSyCsBa0Nb3AZz_8fcX4ShBDusN74GmlGt-c",
  GOOGLE_MAPS_SETTINGS: {
    center: {
      lat: 37.777244,
      lng: -122.5153931,
    },
    zoom: 12,
  },
};

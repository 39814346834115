import PropTypes from "prop-types"
import qs from "query-string"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import {
  checkStripeEnabled, confirmCompanyInfo,
  fetchInvoice,
  navigateTo
} from "../../../../../redux/actions"
import Plaid from "./Plaid"
import Stripe from "./Stripe"

const propTypes = {
  customAccountId: PropTypes.string,
  confirmCompanyInfo: PropTypes.func,
  isCustomAccountVerified: PropTypes.bool,
  checkStripeEnabled: PropTypes.func
};




const ACCOUNT_TABS = ["opc", "opk", "stripe", "plaid", "depts"];
const DEFAULT_SECTION_INDEX = 0;

const AccountContent = props => {
  const {
    customAccountId,
    confirmCompanyInfo,
    isCustomAccountVerified,
    checkStripeEnabled
  } = props;


  const history = useHistory();
  const { tab } = qs.parse(history.location.search);
  const currentTabIndex =
    ACCOUNT_TABS.indexOf(tab) > 0 &&
    ACCOUNT_TABS.indexOf(tab) < ACCOUNT_TABS.length
      ? ACCOUNT_TABS.indexOf(tab)
      : DEFAULT_SECTION_INDEX;
  const [tabIndex, setTabIndex] = useState(currentTabIndex);

  useEffect(() => {
    confirmCompanyInfo();
    if (customAccountId && !isCustomAccountVerified) checkStripeEnabled();
  });

  const setQueryParam = paramValue =>
    navigateTo(`/my-office?tab=${ACCOUNT_TABS[paramValue]}`);

  const renderTab = (title, disabled = false) => (
    <Tab disabled={disabled}>{title}</Tab>
  );

  const renderTabPanel = (
    ContentPanel,
    customAccountId,
    isCustomAccountVerified
  ) => (
    <TabPanel className="account__tabs__panel">
      <ContentPanel
        customAccountId={customAccountId}
        isCustomAccountVerified={isCustomAccountVerified}
      />
    </TabPanel>
  );

  return (
    <div className="account-content-panel">
      <div className="d-flex align-items-center" style={{ gap: "15px" }}>
        {/* <figure>
          <img 
            src="https://b2b.securetrade.pro/images/stp-color.svg" 
            alt="Buy and Sell online with Secure Trade Pro" 
            title="Buy and Sell online with Secure Trade Pro" 
            widht="50px" height="50px"
            />
        </figure> */}
        <h1>Manage your accounts</h1>
      </div>
      <div className="mt-5">
        <p>
          <a href="https://account.opnplatform.com/dashboard" target="_blank" title="Secure Trade online">Click here to manage your accounts</a> on our secured payment platform.
        </p>
      </div>
    </div>
  );
};

AccountContent.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
  customAccountId: state.settings.companyInfo.custom_account_id,
  isCustomAccountVerified:
    state.settings.companyInfo.is_custom_account_verified,
});

const mapDispatchToProps = {
  navigateTo,
  fetchInvoice,
  confirmCompanyInfo,
  checkStripeEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountContent);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import { useHistory, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./Market.scss";
import ContentPanel from "./ContentPanel";
import {
  navigateTo,
  fetchSubscriptionStatus,
  confirmOrderLimit,
  confirmCompanyInfo,
  setTabIndex,
} from "../../../redux/actions";
import AuthService from "../../../services/auth";
import CustomButton from "../../../_shared/components/Button";

const propTypes = {
  navigateTo: PropTypes.func,
  fetchSubscriptionStatus: PropTypes.func,
  confirmCompanyInfo: PropTypes.func,
  user: PropTypes.object,
  setTabIndex: PropTypes.func,
  tabIndex: PropTypes.number,
};

const MARKET_TABS = ["offers", "requests"];
const DEFAULT_SECTION_INDEX = 0;

const Market = props => {
  const {
    navigateTo,
    fetchSubscriptionStatus,
    user,
    confirmOrderLimit,
    hasExhaustOrderLimit,
    confirmCompanyInfo,
    tabIndex,
    setTabIndex,
  } = props;
  const { subscription } = user || {};
  const history = useHistory();
  const { productId } = useParams();
  const { market } = qs.parse(history.location.search);
  const currentTabIndex =
    MARKET_TABS.indexOf(market) > 0 &&
    MARKET_TABS.indexOf(market) < MARKET_TABS.length
      ? MARKET_TABS.indexOf(market)
      : DEFAULT_SECTION_INDEX;
  // const [tabIndex, setTabIndex] = useState(currentTabIndex);
  console.log("tabIndex :", tabIndex);
  const [showUpgradeCard, setShowUpgradeCard] = useState(true);
  const isLoggedIn = AuthService.isLoggedIn();

  useEffect(() => {
    setTabIndex(currentTabIndex);
    if (isLoggedIn) {
      fetchSubscriptionStatus();
      confirmOrderLimit();
      confirmCompanyInfo();
    }
  }, []);

  const setQueryParam = paramValue => {
    if (productId) {
      navigateTo(`/market?market=${MARKET_TABS[paramValue]}`);
    } else {
      history.replace({ search: `?market=${MARKET_TABS[paramValue]}` });
    }
  };

  const redirectToPricePage = () => {
    navigateTo("/pricing");
  };

  return (
    <main className="market">
      {isLoggedIn && hasExhaustOrderLimit && (
        <div className="limit-alert">
          <p>
            <i className="fas fa-exclamation-triangle" />
            Your plan limits have been exhausted. If you want to place more
            offers to the platform, you have to upgrade your plan.{" "}
            <a
            href="https://pricing.opnplatform.com/basic"
            title="Upgrade now - Pricing"
          >
              <strong>Upgrade now</strong>
            </a>
          </p>
        </div>
      )}
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              className="market__tabs"
              selectedIndex={tabIndex}
              onSelect={nextTabIndex => {
                console.log("nextTabIndex :", nextTabIndex);
                setQueryParam(`${nextTabIndex}`);
                setTabIndex(nextTabIndex);
              }}
            >
              <Row>
                <Col md="2" className="tab-list-wrap">
                  <TabList className="market__tabs__sidebar">
                    <Tab>
                      <img
                        className="icon"
                        src={`${process.env.IMAGE_URL}/assets/images/svg/icons/brief.svg`}
                        alt=""
                      />
                      Offer
                    </Tab>
                    <Tab>
                      <img
                        className="icon"
                        src={`${process.env.IMAGE_URL}/assets/images/svg/icons/request.svg`}
                        alt=""
                      />
                      Request
                    </Tab>
                  </TabList>
                </Col>

                <Col md="8" className="tab-panel-wrap">
                  <TabPanel className="market__tabs__panel">
                    <ContentPanel />
                  </TabPanel>
                  <TabPanel className="market__tabs__panel">
                    <ContentPanel />
                  </TabPanel>
                </Col>
              </Row>
            </Tabs>
          </Col>
        </Row>
      </Container>
      {subscription && subscription.tariff === "BASIC" && showUpgradeCard && (
        <div className="upgrade-cta">
          <i
            onClick={() => setShowUpgradeCard(false)}
            className="fas fa-times"
          />
          <div className="upgrade-cta__inner">
            <div>
              <div className="logo">
                <img
                  className="img-fluid"
                  src="/images/opn-logo-no-bg.png"
                  alt="opn logo"
                />
              </div>
            </div>
            <div className="cta">
              <p>
                Get the power of <strong>Business Plan</strong> and unlock more
                features!
              </p>
              <a
            href="https://pricing.opnplatform.com/basic"
            title="Upgrade now - Pricing"
          >
              <strong>Upgrade now</strong>
            </a>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

Market.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
  user: state.auth.user,
  hasExhaustOrderLimit: state.settings.exhaustOrderLimit,
  tabIndex: state.ui.tabIndex,
});

const mapDispatchToProps = {
  navigateTo,
  fetchSubscriptionStatus,
  confirmOrderLimit,
  confirmCompanyInfo,
  setTabIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(Market);

import React from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../../../_shared/components/Button";
import { Col, Form, FormGroup, Row, Container } from "reactstrap";
import {
  TextInputField,
  CheckBoxField,
  SelectInputField,
} from "../../../../../_shared/components/ReduxFormFields";
import DatePickr from "../../../../../_shared/components/ReduxFormFields/ReactDatePicker";
import "./PreOrderForm.scss";
import PreOrderValidationFunc from "./PreOrderValidation";
import { deliveryMethods } from "../../../../../_shared/defs";
       
const propTypes = {
  formLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  formLoading: false,
};

const PreOrderForm = props => {
  const {
    handleSubmit,
    formLoading,
    submitting,
    pristine,
    invalid,
    formData,
    purpose,
  } = props;
  const { goBack } = useHistory();
  const { accept_condition } = formData || {};
  const isPurpose = purpose === "SELL";

  const renderSteps = step => <div className="divider-right">Step {step}</div>;

  const renderSelectDropdown = () => [
    {
      label: "immediately",
      value: "immediately",
    },
    {
      label: "30 days",
      value: "30days",
    },
    {
      label: "60 days",
      value: "60days",
    },
    {
      label: "90 days",
      value: "90days",
    },
  ];

  const currencyDropdown = () => [
    {
      label: "USD",
      value: "USD",
    },
  ];

  const variantDropdown = () => {
    return deliveryMethods.map(({ name, code }) => ({
      label: name,
      value: code,
    }));
  };

  const renderOfferForm = () => (
    <Row>
      <Col md="12" className="p-0">
        {renderSteps(1)}
        <FormGroup className="w-50">
          <Field
            name="quantity"
            label="Quantity"
            id="quantity"
            type="number"
            disabled={formLoading}
            component={TextInputField}
            placeholder="Quantity"
          />
        </FormGroup>

        {renderSteps(2)}
        <FormGroup className="w-50">
          <Field
            name="destination"
            label="Destination"
            id="destination"
            type="text"
            disabled={formLoading}
            component={TextInputField}
            placeholder="Destination"
          />
        </FormGroup>
        <FormGroup className="w-50">
          <Field
            name="due_date"
            label="Due date"
            type="text"
            id="due_date"
            disabled={formLoading}
            component={DatePickr}
            placeholder="Due date"
          />
        </FormGroup>
        <FormGroup className="w-50">
          <Field
            name="payment"
            label="Payment conditions"
            type="text"
            id="payment"
            options={renderSelectDropdown()}
            disabled={formLoading}
            component={SelectInputField}
            placeholder="Payment conditions"
          />
        </FormGroup>

        {renderSteps(3)}
        <FormGroup className="w-50">
          <Field
            name="accept_condition"
            label="Accept payment terms"
            type="checkbox"
            id="accept_condition"
            disabled={formLoading}
            component={CheckBoxField}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const renderRequestForm = () => (
    <Row>
      <Col md="12" className="p-0">
        {renderSteps(1)}
        <FormGroup className="price-form-control w-50">
          <Field
            name="currency"
            label="currency"
            id="currency"
            disabled
            options={currencyDropdown()}
            component={SelectInputField}
            placeholder="currency"
            isClearable={false}
          />
          <Field
            name="price"
            label="Price"
            id="price"
            type="text"
            className="amount"
            component={TextInputField}
            placeholder="Price"
          />
        </FormGroup>

        {renderSteps(2)}
        <FormGroup className="w-50">
          <Field
            name="location"
            label="Location"
            id="location"
            type="text"
            disabled={formLoading}
            component={TextInputField}
            placeholder="Location"
          />
        </FormGroup>
        <FormGroup className="w-50">
          <Field
            name="variant"
            label="Delivery Variant"
            id="variant"
            options={variantDropdown()}
            className="textarea"
            component={SelectInputField}
            isSearchable={true}
            placeholder="Delivery Variant"
          />
        </FormGroup>
        <FormGroup className="w-50 pt-4">
          <Field
            name="ship"
            label="Ready for shipment"
            type="checkbox"
            id="ship"
            disabled={formLoading}
            component={CheckBoxField}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  return (
    <Form className="pre-order-form" onSubmit={handleSubmit}>
      <Container>
        {isPurpose ? renderOfferForm() : renderRequestForm()}
        <Row>
          <Col md="12" className="deals-form__cta">
            <CustomButton name="Cancel" btnType="no-border" action={goBack} />
            <CustomButton
              faName="fa-tag"
              name={isPurpose ? "Confirm order" : "make an offer"}
              loading={formLoading}
              type="submit"
              disabled={invalid || formLoading || pristine || submitting}
            />
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

PreOrderForm.propTypes = propTypes;
PreOrderForm.defaultProps = defaultProps;

const formName = "preOrderForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: PreOrderValidationFunc,
})(PreOrderForm);

// export default connectedForm;
export default connect(
  state => ({
    initialValues: { currency: { label: "USD", value: "USD" } },
    formData: getFormValues(formName)(state),
  }),
  {
    change,
  }
)(connectedForm);
